import { navigate } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import {
  IEnrolmentStatus,
  ILearnerImport,
  ILearnerInList,
} from '../../../context-providers/learner/learner-reducer';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import { formatDateTime } from '../../../utils/dates';
import { usePagedRequest } from '../../../utils/paged-request';
import Button from '../../atoms/button/Button';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import GridFlexItem from '../../atoms/grid-flex/GridFlexItem';
import Paging from '../../atoms/paging/Paging';
import Stack from '../../atoms/stack/Stack';
import Table from '../../atoms/table/Table';
import { SingleLineCell, TableActions } from '../../atoms/table/Table.style';
import {
  IconAssessments,
  IconCloseCircle,
  IconDelete,
  IconEnrol,
  IconPlus,
  IconShow,
  IconUpload,
} from '../../icons';
import { FullWidthContainer } from '../../layout/Layout.style';
import EmployerFilter from '../../atoms/filter/filter-types/employer-filter';
import { Flex, FlexGrid } from '../../atoms/custom-elements/Flex.style';
import LearnerNameFilter from '../../atoms/filter/filter-types/learner-name-filter';
import CourseFilter from '../../atoms/filter/filter-types/course-filter';
import ActionLink from '../../atoms/custom-elements/linkaction.style';
import { deleteQueryParams, getAllQueryVar } from '../../../utils/query-vars';
import AssignmentStatusBadge from '../../atoms/status-badge/AssignmentStatusBadge';
import { ImportLearnerFeatureStatus } from '../../../context-providers/enums/global-enums';
import { fetchAPI } from '../../../utils/fetch-api';
import { CloseableBanner } from '../closeable-banner/closeable-banner';
import { userHasPermission } from '../../../context-providers/user/user-permission';
import { Permission } from '../../../context-providers/enums/global-enums';
import { getUserClaims } from '../../../utils/jwt-decode';
import CreateLearner from '../../modals/learner/CreateLearner';
import DeleteData from '../../modals/forms/DeleteData';
import KebabMenu, { IKebabMenuItem } from '../../atoms/kebab-menu/KebabMenu';
import EnrolmentStatusBadge from '../../atoms/status-badge/EnrolmentStatusBadge';
import { MediumText } from './LearnerList.style';
import EnrolmentStatusFilter from '../../atoms/filter/filter-types/enrolment-status-filter';

const isBrowser = typeof window !== 'undefined';

export interface ILearnerList {
  enrolmentStatuses: IEnrolmentStatus[];
}

const LearnerList = ({}: ILearnerList) => {
  if (isBrowser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const user = getUserClaims();
  let canCreateLearner: boolean = false;
  let canDeleteLearner: boolean = false;
  let canImportLearner: boolean = false;

  if (user) {
    canCreateLearner = userHasPermission(
      user.permissions,
      Permission.CreateLearner,
    );
    canDeleteLearner = userHasPermission(
      user.permissions,
      Permission.DeleteLearner,
    );
    canImportLearner = userHasPermission(
      user.permissions,
      Permission.ImportLearners,
    );
  }

  const showModal = useShowModal();
  const extractQueryVars = (name: string): string[] => {
    const params = getAllQueryVar(name) ?? [];
    if (params.length === 1) {
      return params[0].split(',');
    }
    return params;
  };
  const [canImport, setCanImport] = useState(
    ImportLearnerFeatureStatus.Disabled,
  );

  const learnersParams = extractQueryVars('learners');
  const employerParams = extractQueryVars('employers');
  const coursesParams = extractQueryVars('courses');
  const enrolStatusParams = extractQueryVars('enrolmentStatuses');
  const hasActiveFilters =
    learnersParams.length > 0 ||
    employerParams.length > 0 ||
    coursesParams.length > 0 ||
    enrolStatusParams.length > 0;

  const learners = usePagedRequest<ILearnerInList>('filters/learnerspage', {
    sortColumnName: 'lastModified',
    learners: learnersParams.join(','),
    employers: employerParams.join(','),
    courses: coursesParams.join(','),
    enrolmentStatuses: enrolStatusParams.join(','),
  });

  const getLearnerFeatureStatus = useCallback(async () => {
    const response = await fetchAPI<ILearnerImport>({
      path: 'feature',
      method: 'GET',
    });
    if (response.success) {
      if (
        response.body.importLearners === ImportLearnerFeatureStatus.Enabled ||
        response.body.importLearners ===
          ImportLearnerFeatureStatus.LearnerImportInProgress
      ) {
        return setCanImport(response.body.importLearners);
      }
    }
    return setCanImport(ImportLearnerFeatureStatus.Disabled);
  }, []);

  const onConfirmDelete = async (learner: ILearnerInList) => {
    const res = await fetchAPI<string>({
      path: `Learners/${learner?.id}/delete`,
      method: 'DELETE',
      responseType: 'text',
    });

    if (res.success) {
      window.location.reload();
    }
  };

  const enrolmentStatusChanged = (
    learner: ILearnerInList,
    statusId: number,
  ): void => {
    updateEnrolmentStatus(learner, statusId);
  };

  const updateEnrolmentStatus = async (
    learner: ILearnerInList,
    newStatusId: number,
  ) => {
    const res = await fetchAPI<string>({
      path: `Learners/${learner.id}/updateEnrolmentStatus/${newStatusId}`,
      method: 'POST',
      responseType: 'text',
    });

    if (res.success) {
      window.location.reload();
    }
  };

  useEffect(() => {
    getLearnerFeatureStatus();
  }, [getLearnerFeatureStatus]);

  const viewLearner = (data: ILearnerInList) => {
    navigate(`/learner/?lid=${data.id}`);
  };

  const dataMenuItems = (data: ILearnerInList): IKebabMenuItem[] => {
    let menuItems: IKebabMenuItem[] = [];

    const viewItem: IKebabMenuItem = {
      name: 'View',
      qatag: `learner-btn-view-${data?.name?.replace(/\s/g, '-') ?? 'empty'}`,
      disabled: false,
      onClick: () => {
        ReactGA.event({
          category: 'Button',
          action: 'View learner',
        });
        viewLearner(data);
      },
      iconLeft: <IconShow />,
      srOnly: <span className="sr-only">Edit {data.name} learner</span>,
      className: 'not-rounded',
    };
    menuItems.push(viewItem);
    if (data.completedStatus === 0) {
      const deleteItem: IKebabMenuItem = {
        name: 'Delete',
        qatag: `learner-btn-delete-${
          data?.name?.replace(/\s/g, '-') ?? 'empty'
        }`,
        disabled: !canDeleteLearner,
        onClick: () => {
          showModal(DeleteData, {
            onConfirmDelete: () => {
              onConfirmDelete(data);
            },
          });
        },
        iconLeft: <IconDelete fill={!canDeleteLearner ? '#ccc' : ''} />,
        srOnly: <span className="sr-only">Delete {data.name} learner</span>,
        className: 'not-rounded',
      };
      menuItems.push(deleteItem);
    }
    if (data.enrolmentStatus !== 1) {
      const inProgressItem: IKebabMenuItem = {
        name: 'In progress',
        qatag: `learner-btn-in-progress-${
          data?.name?.replace(/\s/g, '-') ?? 'empty'
        }`,
        disabled: false,
        onClick: () => {
          ReactGA.event({
            category: 'Button',
            action: 'In progress',
          });
          enrolmentStatusChanged(data, 1);
        },
        iconLeft: <IconAssessments />,
        srOnly: <span className="sr-only">View {data.name} learner</span>,
        className: 'not-rounded',
      };
      menuItems.push(inProgressItem);
    }
    if (
      data.completedStatus !== 0 &&
      data.completedStatus !== 1 &&
      data.enrolmentStatus !== 2
    ) {
      const enrolItem: IKebabMenuItem = {
        name: 'Enrol',
        qatag: `learner-btn-enrol-${
          data?.name?.replace(/\s/g, '-') ?? 'empty'
        }`,
        disabled: false,
        onClick: () => {
          ReactGA.event({
            category: 'Button',
            action: 'Enrol',
          });
          enrolmentStatusChanged(data, 2);
        },
        iconLeft: <IconEnrol />,
        srOnly: <span className="sr-only">View {data.name} learner</span>,
        className: 'not-rounded',
      };
      menuItems.push(enrolItem);
    }
    if (data.enrolmentStatus !== 3) {
      const withdrawItem: IKebabMenuItem = {
        name: 'Withdraw',
        qatag: `learner-btn-withdraw-${
          data?.name?.replace(/\s/g, '-') ?? 'empty'
        }`,
        disabled: false,
        onClick: () => {
          ReactGA.event({
            category: 'Button',
            action: 'Withdraw',
          });
          enrolmentStatusChanged(data, 3);
        },
        iconLeft: <IconCloseCircle />,
        srOnly: <span className="sr-only">View {data.name} learner</span>,
        className: 'not-rounded',
      };
      menuItems.push(withdrawItem);
    }

    return menuItems;
  };

  const renderRow = (data: ILearnerInList) => [
    <Stack stackMultiplier={1} key="1">
      <strong>{data.name}</strong>
      {data.uln && (
        <div>
          <MediumText>ULN: {data.uln}</MediumText>
        </div>
      )}
      {data.dateOfBirth && (
        <div>
          <MediumText> DOB: {data.dateOfBirth}</MediumText>
        </div>
      )}
    </Stack>,
    <SingleLineCell key="2">{data.employerName}</SingleLineCell>,
    <SingleLineCell key="3">{data.courseName}</SingleLineCell>,
    formatDateTime(data.lastModified),
    <Stack stackMultiplier={2.5} key="4">
      <div data-qa={`learner-list-completed-status-${data.name}`}>
        <AssignmentStatusBadge status={data.completedStatus} />
      </div>
    </Stack>,
    <Stack stackMultiplier={2.5} key="6">
      <div data-qa={`learner-list-enrolment-status-${data.name}`}>
        <EnrolmentStatusBadge status={data.enrolmentStatus} />
      </div>
    </Stack>,
    <TableActions key="7">
      <KebabMenu items={dataMenuItems(data)} canCreateForm={true}></KebabMenu>
    </TableActions>,
  ];

  const clearAllFilters = () => {
    const url = deleteQueryParams(
      'learners',
      'employers',
      'courses',
      'enrolmentStatuses',
    );
    navigate(url || '');
  };

  return (
    <FullWidthContainer>
      <Stack stackMultiplier={2.5}>
        {canImport === ImportLearnerFeatureStatus.LearnerImportInProgress && (
          <CloseableBanner text="This page will automatically update when your current import is complete. After that, you’ll be able to import more learners." />
        )}
        <GridFlex gutters={false} align="flex-end" justify="space-between">
          <GridFlexItem gutters={false} small={6}>
            <Stack stackMultiplier={1}>
              <h1>Learner records</h1>
              <p>
                You can add a new learner record, manage records or assign
                learners to an employer.
              </p>
            </Stack>
          </GridFlexItem>
          <Flex gap={32}>
            {(canImport === ImportLearnerFeatureStatus.Enabled ||
              canImport ===
                ImportLearnerFeatureStatus.LearnerImportInProgress) && (
              <Button
                iconRight={<IconUpload responsive />}
                variant="secondary"
                dataQa="learners-btn-import"
                disabled={
                  !canImportLearner ||
                  canImport ===
                    ImportLearnerFeatureStatus.LearnerImportInProgress
                }
                onClick={() => {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Import multiple learners',
                  });
                  navigate('/bulk-import-learners');
                }}
              >
                Import learners
              </Button>
            )}
            <Button
              iconLeft={<IconPlus responsive />}
              dataQa="learners-btn-add"
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Add new learner',
                });
                showModal(CreateLearner, {
                  onCreated: (learnerId: string) => {
                    navigate(`/learner/?lid=${learnerId}`);
                  },
                });
              }}
              disabled={!canCreateLearner}
            >
              Add new learner
            </Button>
          </Flex>
        </GridFlex>
        <Stack stackMultiplier={1}>
          <FlexGrid column={2} row={1}>
            <LearnerNameFilter />
            <EmployerFilter />
            <CourseFilter />
            <EnrolmentStatusFilter />
            {hasActiveFilters && (
              <ActionLink onClick={clearAllFilters}>Clear all</ActionLink>
            )}
          </FlexGrid>
          <Table
            caption="List of learners"
            data={learners.data}
            headerItems={[
              { label: 'LEARNER NAME', sortColumnName: 'learnername' },
              { label: 'EMPLOYER' },
              { label: 'COURSE' },
              { label: 'LAST MODIFIED', sortColumnName: 'lastModified' },
              { label: 'RECORD STATUS', sortColumnName: 'completedstatus' },
              { label: 'ENROLMENT STATUS' },
              { label: '' },
            ]}
            renderRow={renderRow}
            paged={learners.pagedParams}
          />
        </Stack>
        <Paging pageCount={learners.pageCount} />
      </Stack>
    </FullWidthContainer>
  );
};

export default LearnerList;
