import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import {
  deleteQueryParams,
  getAllQueryVar,
  urlWithSetMultipleQueryVar,
} from '../../../../utils/query-vars';
import TableFilter from '../TableFilter';
import FilterService from '../service/filter-service';
import { FilterEntityResponse } from '../models/filter-response';
import { FilterItem } from '../models/filter-item';
import { TabColour } from '../../pill/tab-color';

const LearnerNameFilter = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<FilterEntityResponse[]>([]);

  const load = async () => {
    const response = await FilterService.loadLearners();
    if (response.success) {
      setItems(response.body);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const onItemChanged = (selectedItems: FilterItem[]): void => {
    let url = '/';
    if (selectedItems.length === 0) {
      url = deleteQueryParams('learners');
    } else {
      url = urlWithSetMultipleQueryVar(
        'learners',
        selectedItems.map((i) => i.id.toString()),
      );
    }

    navigate(url);
  };

  useEffect(() => {
    load();
  }, []);

  let learnerQuery = getAllQueryVar('learners') ?? [];
  if (learnerQuery.length === 1) {
    learnerQuery = learnerQuery[0].split(',');
  }

  const checkedItems: FilterItem[] = items.map((i) => ({
    id: i.id,
    name: i.name,
    color: TabColour.blue,
    selected: learnerQuery.includes(i.id.toString()),
    subText: i.subText,
  }));

  return (
    <TableFilter
      filterName="learner-name"
      items={checkedItems}
      title="Filter by learner name"
      disabled={loading}
      searchTitle="Search learner"
      onItemChanged={onItemChanged}
    />
  );
};

export default LearnerNameFilter;
