import React, { useState } from 'react';
import { color } from '../../config';
import { IconClose, IconInfo } from '../../icons';
import { CloseableBannerWrapper } from './closeable-banner.style';

interface ICloseableBannerProps {
  text: string;
}

export const CloseableBanner = ({ text }: ICloseableBannerProps) => {
  const [closed, setClosed] = useState<boolean>(false);

  const closeBanner = () => {
    setClosed(true);
  };

  return (
    <>
      {!closed && (
        <CloseableBannerWrapper>
          <IconInfo />
          <span>{text}</span>
          <button
            onClick={() => {
              closeBanner();
            }}
            type="submit"
            aria-label="Close"
          >
            <IconClose fill={color.primary.base} width={24} height={24} />
          </button>
        </CloseableBannerWrapper>
      )}
    </>
  );
};

export default CloseableBanner;
