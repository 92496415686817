import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout/Layout';
import Head from '../../components/layout/Head';
import LearnerList from '../../components/organisms/learner-list/LearnerList';
import AuthWrapper from '../../components/layout/AuthWrapper';
import { IEnrolmentStatus } from '../../context-providers/learner/learner-reducer';
import ExportLearnerHelper from '../../utils/exportLearnerHelper';

const ListLearnersPage = () => {
  const [enrolmentStatuses, setEnrolmentStatuses] = useState<
    IEnrolmentStatus[]
  >([]);
  const [statusesLoaded, setStatusesLoaded] = useState<boolean>(false);
  const [statusesLoading, setStatusesLoading] = useState<boolean>(false);
  const loadEnrolmentStatus = () => {
    ExportLearnerHelper.getEnrolmentStatuses().then((response) => {
      setStatusesLoading(true);
      if (!statusesLoaded && !statusesLoading) {
        setEnrolmentStatuses(response);
        setStatusesLoaded(true);
      }
    });
  };

  useEffect(() => {
    loadEnrolmentStatus();
  }, [loadEnrolmentStatus]);

  return (
    <AuthWrapper>
      <Head title="Learners" />
      <Layout
        section="learners"
        breadcrumbs={[{ link: '/', label: 'Home' }, { label: 'Learners' }]}
      >
        <LearnerList enrolmentStatuses={enrolmentStatuses} />
      </Layout>
    </AuthWrapper>
  );
};

export default ListLearnersPage;
