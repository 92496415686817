import styled from 'styled-components';

export const CloseableBannerWrapper = styled.div`
  display: flex;
  border: solid 2px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #0047d4;
  background: #f1f3fc;
  border: 1px solid #1f5ed9;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 18px;
  align-items: center;

  span {
    margin-left: 18px;
  }

  button {
    display: flex;
    border: none;
    background: none;
    margin-left: auto;
    cursor: pointer;
  }
`;
