import { produce } from 'immer';
import set from 'lodash/set';
import React, { SyntheticEvent, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import { MaxLength } from '../../../context-providers/enums/global-enums';

export interface ICreateLearner {
  onCreated?: (learnerId: string) => void;
}

const CreateLearner = ({ onCreated }: ICreateLearner) => {
  const closeModal = useCloseModal();

  const [learner, setLearner] = useState({
    firstName: '',
    surname: '',
    emailAddress: '',
    enrolmentStatus: 1,
  });

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    ReactGA.event({
      category: 'Button',
      action: 'Create learner modal - Create record',
    });

    // send form data to api
    const res = await fetchAPI<string>({
      path: 'learners',
      body: learner,
      method: 'POST',
    });
    if (res.success) {
      onCreated && onCreated(res.body);
      closeModal();
    }
  };

  const handleChange = (value: string, fieldName: string) => {
    setLearner(
      produce(learner, (newLearner) => {
        set(newLearner, fieldName, value);
        return newLearner;
      }),
    );
  };

  // field errors
  const firstNameErr = validateField(learner.firstName, {
    required: true,
    firstName: true,
  });
  const surnameErr = validateField(learner.surname, {
    required: true,
    lastName: true,
  });
  const emailErr = validateField(learner.emailAddress, {
    required: true,
    email: true,
  });
  const isValid = !firstNameErr && !surnameErr && !emailErr;

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event);
      }}
    >
      <Stack stackMultiplier={2}>
        <Stack stackMultiplier={0.5}>
          <h2>Create learner record</h2>
          <p>
            Please enter the basic details to create the new learner record.
          </p>
        </Stack>
        <Stack stackMultiplier={0.5}>
          <TextInput
            label="First name"
            value={learner.firstName}
            id="firstName"
            error={firstNameErr}
            onChangeText={(str) => handleChange(str, 'firstName')}
            dataQa="createLearner-input-firstName"
          />
          <TextInput
            label="Surname"
            value={learner.surname}
            id="surname"
            error={surnameErr}
            onChangeText={(str) => handleChange(str, 'surname')}
            dataQa="createLearner-input-surname"
          />
          <TextInput
            label="Email address"
            value={learner.emailAddress}
            id="emailAddress"
            maxLength={MaxLength.EmailAddress}
            error={emailErr}
            onChangeText={(str) => handleChange(str, 'emailAddress')}
            dataQa="createLearner-input-email"
          />
        </Stack>

        <ButtonRow>
          <Button
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'Create learner modal - cancel',
              });
              closeModal();
            }}
            variant="subtle"
            dataQa="createLearner-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            dataQa="createLearner-btn-submit"
          >
            Create record
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default CreateLearner;
