import React from 'react';
import StatusBadge from './StatusBadge';
import { color } from '../../config';

const EnrolmentStatus = [
  {
    label: 'In progress',
    color: color.status.notStarted,
    border: color.status.notStarted,
  },
  {
    label: 'In progress',
    color: color.status.notStarted,
    border: color.status.notStarted,
  },
  {
    label: 'Enrolled',
    color: color.status.complete,
    border: color.status.complete,
  },
  {
    label: 'Withdrawn',
    color: color.status.enrolWithdrawn,
    border: color.status.notStarted,
    text: color.status.notStarted,
  },
  {
    label: 'RPL successful',
    color: color.status.enrolRPLSuccessful,
    border: color.status.enrolRPLSuccessful,
  },
  {
    label: 'RPL unsuccessful',
    color: color.status.enrolRPLUnsuccessful,
    border: color.status.enrolRPLUnsuccessful,
  },
];

export interface IEnrolmentStatusBadge {
  status: number;
}

const EnrolmentStatusBadge = ({ status }: IEnrolmentStatusBadge) => (
  <StatusBadge
    color={EnrolmentStatus[status].color}
    border={EnrolmentStatus[status].border}
    text={EnrolmentStatus[status].text}
  >
    {EnrolmentStatus[status].label}
  </StatusBadge>
);

export default EnrolmentStatusBadge;
